<template>
  <v-container class="issue-bulk px-0">
    <v-row>
      <v-col cols="6" class="">
        <h2 class="title_header pr-4">
          {{ $t('inBulkModal.title') }}
        </h2>
        <div class="steps">
          {{ $t('signup.step[0]') }} {{ step + 1 }} {{ $t('signup.step[1]') }} 2
        </div>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn
          v-if="step == 0"
          class="advance-btn mr-5"
          @click="downloadTemplate()"
        >
          {{ $t('button.download') }}
        </v-btn>
        <v-btn v-if="step == 1" class="back mr-4" @click="back()">{{
          $t('button.back')
        }}</v-btn>
        <v-btn
          class="next"
          :disabled="!file"
          :loading="importingCredentials"
          @click="next()"
        >
          {{ buttonText }}
        </v-btn>
      </v-col>
    </v-row>
    <v-card flat class="pa-8 mt-6">
      <v-row v-if="!showConfirmData">
        <v-col cols="12" md="10" class="">
          <p class="subtitle_header mb-6">
            {{ $t('inBulkModal.text1') }}
          </p>
          <p class="subtitle_text mb-0" style="max-width: 800px">
            {{ $t('inBulkModal.text2') }}
          </p>
        </v-col>
        <v-col id="file-drag-drop" cols="12" class="">
          <input
            type="file"
            id="pdf"
            ref="pdf"
            style="visibility: hidden; width: 0px; height: 0px"
            accept=".csv"
            multiple="multiple"
            v-on:change="handleFileUpload()"
          />

          <div
            class="outside-box"
            :class="{ 'error-upload': errorMsg }"
            v-on:click="openFileSelection"
          >
            <form
              class="drag-drop-form"
              v-on:dragover="onDragOver"
              v-on:dragleave="onDragLeave"
              v-bind:class="{ hightlight: hightlight }"
              style="cursor: pointer"
              ref="fileform"
            >
              <div style="display: flex">
                <icon-upload class="mx-auto" />
              </div>
              <div>
                <p class="drop-files drag-box-text mx-auto my-0">
                  {{ $t('inBulkModal.upload[0]') }}
                  <label style="cursor: pointer" class="browse-file">{{
                    $t('inBulkModal.upload[1]')
                  }}</label>
                  {{ $t('inBulkModal.upload[2]') }}
                </p>
              </div>
            </form>
          </div>

          <p v-if="errorMsg" class="light_text text-center mt-2 error-upload">
            {{ errorMsg }}
          </p>
          <p v-else class="light_text text-center mt-2">
            {{ $t('inBulkModal.hint') }}
          </p>
        </v-col>

        <v-col cols="12" v-if="file" class="pt-4">
          <div class="file-listing">
            <IconFile class="mr-2" />
            <p>{{ file.name }}</p>
            <v-spacer />
            <button
              class="btn-close"
              @click="file = null"
              aria-label="Close modal"
            >
              <IconClose />
            </button>
          </div>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" md="10" class="pb-0">
          <p class="subtitle_text_strong" style="max-width: 580px">
            {{ $t('inBulkModal.text3') }}
          </p>
        </v-col>

        <v-col cols="12" md="5" class="pb-1">
          <v-data-table
            class="table_in_bulk"
            :headers="headers"
            :items="templateTable"
            :no-results-text="$t('table.noData')"
            :items-per-page="10"
            :hide-default-footer="templateTable.length < 10"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="(row, indexR) in items"
                  :key="indexR"
                  @mouseover="!importingCredentials && changeCredential(row)"
                >
                  <td v-for="(item, indexL) in row" :key="indexL">
                    <p>
                      {{ reduceText(item, 30)[0] }}
                      <v-tooltip
                        v-if="reduceText(item, 30).length > 1"
                        bottom
                        content-class="tooltip-desc"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <more-info> </more-info>
                          </span>
                        </template>
                        <p>{{ item }}</p>
                      </v-tooltip>
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
        <v-col
          v-if="customTemplateName !== 'templateEditor'"
          cols="12"
          md="7"
          class="pb-1"
        >
          <CustomCard
            :frontTemplate="templateValues"
            :backTemplate="cardHeaders"
            :tableValues="tableValues"
            :templateValues="templateValues"
            :frontend_props="frontend_props"
            :caName="caName"
            :credentialName="credName"
            :urlPhoto="urlPhoto"
            :height="267"
            :width="378"
          />
        </v-col>
        <v-col v-else cols="12" md="7" class="static_image pt-0">
          <Canvas
            v-if="reload"
            :editable="false"
            :width="sending ? 1080 : 360"
            :style="canvasStyle"
          >
          </Canvas>
        </v-col>
      </v-row>
    </v-card>
    <MessageModal
      v-if="importingCredentials"
      @close="$router.go(-1)"
      :message="popupMessage"
    ></MessageModal>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import IconClose from '@/assets/icon-close.vue';
import IconUpload from '@/assets/icon-upload.vue';
import IconFile from '@/assets/icon-file.vue';
import { IMPORT_FILE, UPLOAD_DOC } from '@/store/actions';
import CustomCard from '@/components/CustomCard';
import MoreInfo from '@/assets/more-info';

import MessageModal from '../../components/TemplateEditor/modals/MessageModal';

import Canvas from '../../components/TemplateEditor/components/Canvas/Canvas';

import { generateImages } from '@/plugins/snapshot';

const API_URL_IMAGES = process.env.VUE_APP_API_URL_IMAGES;

export default {
  name: 'IssueInBulk',
  components: {
    IconClose,
    IconUpload,
    IconFile,
    CustomCard,
    MoreInfo,
    MessageModal,
    Canvas,
  },
  computed: {
    ...mapGetters(['photoURL', 'adminEmail']),
    ...mapGetters('template', [
      'frontend_props',
      'excelTemplate',
      'tid',
      'caName',
      'credName',
      'urlPhoto',
      'cardHeaders',
      'customTemplateName',
    ]),
    canvasStyle() {
      return this.importingCredentials
        ? 'max-height: 820px;'
        : 'max-height: 60vh;';
    },
  },
  beforeDestroy() {
    // this.$store.dispatch('setTemplate', '');
    this.resetTemplateValues();
    window.onbeforeunload = null;
  },
  created() {
    this.debug(`*** ${this.$options.name} ***`);

    this.initialLoad();
  },
  mounted() {
    //Determine if drag and drop functionality is capable in the browser
    this.dragAndDropCapable = this.determineDragAndDropCapable();

    if (this.dragAndDropCapable) {
      /*
      Listen to all of the drag events and bind an event listener to each
      for the fileform.
    */
      [
        'drag',
        'dragstart',
        'dragend',
        'dragover',
        'dragenter',
        'dragleave',
        'drop',
      ].forEach(
        function (evt) {
          this.$refs.fileform.addEventListener(
            evt,
            function (e) {
              e.preventDefault();
              e.stopPropagation();
            }.bind(this),
            false
          );
        }.bind(this)
      );

      this.$refs.fileform.addEventListener(
        'drop',
        function (e) {
          var files = e.dataTransfer.files;
          this.uploadFiles(files);
          // for (let i = 0; i < e.dataTransfer.files.length; i++) {
          //   this.$store.commit("ADD_FILE", e.dataTransfer.files);
          // }
          this.hightlight = false;
        }.bind(this)
      );
    }
  },
  methods: {
    isNFT() {
      return (
        this.adminEmail &&
        this.customTemplate[this.getEmail(this.adminEmail).domain] &&
        this.frontend_props &&
        this.frontend_props.currentLayout &&
        this.frontend_props.currentLayout === 'NFT'
      );
    },
    async toDataURL(url) {
      if (url.startsWith('data:image')) return url;

      return await new Promise((resolve) => {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url + '?_=' + Date.now(), true);
        xhr.onload = function () {
          var reader = new FileReader();
          reader.onloadend = function () {
            resolve(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.onerror = function () {
          resolve(undefined);
          console.error('** An error occurred during the XMLHttpRequest');
        };
        xhr.responseType = 'blob';
        xhr.send();
      });
    },
    async initialLoad() {
      if (this.customTemplateName == 'templateEditor') {
        this.debug('frontend_props', this.frontend_props);

        this.templateCopy = JSON.parse(JSON.stringify(this.frontend_props));

        this.frontend_props.backgroundFront = await this.toDataURL(
          this.frontend_props.backgroundFront
        );
        if (this.frontend_props.backgroundBack)
          this.frontend_props.backgroundBack = await this.toDataURL(
            this.frontend_props.backgroundBack
          );
      }

      this.$store.dispatch('enableBackSide', false);

      this.$store.dispatch('setTemplate', this.frontend_props);

      this.$store.commit('setActiveComponent', '');

      for (var i in this.frontend_props.components) {
        if (this.frontend_props.components[i].templateSide == 'back') {
          if (this.pages.length == 1) {
            this.pages.push('#back');
          }
          this.$store.dispatch('enableBackSide', true);
        }
        if (this.frontend_props.components[i].type == 'image') {
          if (
            this.isNFT() &&
            !this.frontend_props.components[i].src.startsWith('https:') &&
            i === 0
          ) {
            this.frontend_props.components[i].src = await this.toDataURL(
              `${API_URL_IMAGES}/img/stamp-wallid.png`
            );
          } else {
            this.frontend_props.components[i].src = await this.toDataURL(
              this.frontend_props.components[i].src
            );
          }
        }
      }

      this.$store.dispatch('setTemplate', this.frontend_props);
    },
    resetTemplateValues() {
      this.debug('START reset FUNCTION in bulk');
      // for (var i in this.frontend_props.components) {
      //   const component = this.frontend_props.components[i];
      //   if (component.type == 'text') {
      //     this.debug(component);
      //     if (component.originalText) {
      //       this.frontend_props.components[
      //         i
      //       ].text = component.originalText.join('');
      //     } else if (component.baseText) {
      //       // not an array, no need to call join() like in IssueCredentials.vue
      //       this.frontend_props.components[i].text = component.baseText;
      //     }
      //     delete component.originalText;
      //     delete component.baseText;
      //   }
      // }

      this.$store.commit('template/tid', null);

      this.$store.commit('template/frontend_props', this.templateCopy);

      this.$store.dispatch('setTemplate', this.templateCopy);
    },
    async replaceAttributeText(component, cred) {
      // console.log('START REPLACE FUNCTION');
      // console.log(component);
      // console.log(cred);

      // console.log(component.baseText);
      let attrs = component.text.match(/\[[A-Z0-9 ]*\]/g);

      // If the field already has a value we need to get the baseText previously set (1st value change)
      if (
        component.baseText &&
        Array.isArray(component.baseText) &&
        component.originalText
      ) {
        attrs = component.originalText.join(' ').match(/\[[A-Z0-9 ]*\]/g);
      } else if (component.baseText) {
        attrs = component.baseText.match(/\[[A-Z0-9 ]*\]/g);
      }

      // Search the attributes in text for the corrent one component.attr
      if (attrs && attrs.length > 0) {
        let finalText;
        if (Array.isArray(component.baseText) && component.originalText) {
          finalText = component.originalText.join(' ');
        } else {
          finalText = component.baseText || component.text;
        }
        for (let index = 0; index < attrs.length; index++) {
          let el = attrs[index];
          el = el.replace(/\[|\]/g, '');
          // console.log('el', el);
          // console.log(' attr', cred[el]);

          if (!component.baseText) {
            component.baseText = component.text;
          }
          finalText = finalText.replace('[' + el + ']', cred[el]);
        }

        component.text = finalText;
        // console.log('item.text', component.text);

        this.$store.dispatch('setTemplate', this.frontend_props);

        this.$forceUpdate();
      }
    },

    changeCredential(cred) {
      if (this.customTemplateName !== 'templateEditor') {
        this.parseCardValues({ user_data: cred });
      } else {
        if (this.frontend_props.backgroundBack) {
          if (this.pages.length == 1) {
            this.pages.push('#back');
          }
          this.$store.dispatch('enableBackSide', true);
        }
        for (var i in this.frontend_props.components) {
          if (this.frontend_props.components[i].templateSide == 'back') {
            if (this.pages.length == 1) {
              this.pages.push('#back');
            }
            this.$store.dispatch('enableBackSide', true);
          }

          if (
            this.frontend_props.components[i].type == 'text' &&
            this.isNFT()
          ) {
            this.frontend_props.backgroundFront = cred['BACKGROUND'];
          } else if (this.frontend_props.components[i].type == 'text') {
            // console.log(this.components[i]);
            // .filter(function(el) {
            //   return el;
            // });
            this.replaceAttributeText(this.frontend_props.components[i], cred);
            // console.log(this.components[i].baseText);
          }
        }
      }
    },
    downloadTemplate() {
      window.open(this.excelTemplate);
    },
    handleFileUpload() {
      this.uploadFiles(this.$refs.pdf.files);
      const input = this.$refs.pdf;
      input.type = 'text';
      input.type = 'file';
    },
    back() {
      this.showConfirmData = false;
      this.templateValues = [];
      this.templateTable = [];
      this.headers = [];
      this.step = 0;
      this.buttonText = this.$t('button.next');
    },
    next() {
      if (this.showConfirmData) {
        this.import();
      } else {
        this.upload();
      }
    },

    parseCardValues(user) {
      let index = 0;
      this.templateValues = [];

      this.tableValues = user.table_values;
      let checkIfHeadersExist =
        this.headers.length == Object.entries(user.user_data).length;
      for (var [key, value] of Object.entries(user.user_data)) {
        if (!checkIfHeadersExist) {
          this.headers.push({
            text: key,
            align: 'start',
            value: key,
            width: 150,
            sortable: false,
          });
        }
        this.templateValues.push({ attr: key, value: value, index });
        index++;
      }
    },
    upload() {
      let formData = new FormData();

      formData.append('uploadfile', this.file);
      formData.append('tid', this.tid);

      this.$log.debug('upload: ', UPLOAD_DOC);

      this.$store
        .dispatch(UPLOAD_DOC, { formData })
        .then((res) => {
          // self.$router.push("/verify");
          this.import_data = res.data.data;

          console.log('tableValues', this.tableValues);

          // Create table headers (not card)
          let i = 0;
          if (this.tableValues) {
            for (var t of this.tableValues) {
              for ([key, value] of Object.entries(t)) {
                let el = {
                  text: key,
                  align: 'start',
                  value: key + i,
                  width: 150,
                };
                el[key] = value;
                this.headers.push(el);
              }
              i++;
            }
          }

          // Create Initial values for card

          setTimeout(() => {
            this.changeCredential(this.import_data[0].user_data);
          });

          console.log('this.import_data', this.import_data);
          this.initialLoad();
          // Create table headers
          for (var [key, value] of Object.entries(
            this.import_data[0].user_data
          )) {
            this.headers.push({
              text: key,
              align: 'start',
              value: key,
              width: 150,
              sortable: false,
            });
          }

          // Push the rest of users itens to table

          for (var e of this.import_data) {
            let entry = {};

            for ([key, value] of Object.entries(e.user_data)) {
              entry[key] = value;
            }
            let i = 0;
            if (e.table_values) {
              for (t of e.table_values) {
                for ([key, value] of Object.entries(t)) {
                  entry[key + i] = value;
                }
                i++;
              }
            }
            console.log(entry);
            this.templateTable.push(entry);
          }
          console.log(this.templateTable);

          this.showConfirmData = true;
          this.step = 1;

          this.buttonText = this.$t('inBulkModal.buttonIssue');
        })
        .catch((err) => {
          console.error('Error Emiting File:', err);
          // TO DO :: remove file from list
          this.errorMsg = this.$t('inBulkModal.error');
        });
    },

    generateImagesForCredentials(nextCred) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // TODO get pages from credential
          let res = await generateImages(
            this.pages,
            this.tid,
            '1080',
            this.frontend_props.currentLayout
          );
          if (nextCred) {
            this.changeCredential(nextCred);
          }
          setTimeout(() => {
            resolve(res);
          }, 0);
        } catch (error) {
          console.error(error);
          reject(error);
        }
      });
    },
    async import() {
      this.importingCredentials = true;
      this.sending = true;
      this.reload = false;

      this.changeCredential(this.import_data[0].user_data);
      this.$nextTick(async () => {
        this.reload = true;
        setTimeout(async () => {
          this.index = 1;
          while (this.index <= this.import_data.length) {
            // The next credential is passed here and we store the result array in the
            // previous position
            this.import_data[this.index - 1].imgArray =
              await this.generateImagesForCredentials(
                this.import_data[this.index] &&
                  this.import_data[this.index].user_data
              );
            for (
              let i = 0;
              i < this.import_data[this.index - 1].imgArray.length;
              i++
            ) {
              const el = this.import_data[this.index - 1].imgArray[i];
              console.log(el);
            }
            this.index++;
          }
          // this.importingCredentials = false;

          // Hide qrcode
          this.proof_url = null;

          this.$log.debug('IMPORT_FILE', IMPORT_FILE);

          this.$store
            .dispatch(IMPORT_FILE, {
              tid: this.tid,
              import_data: this.import_data,
            })
            .then(() => {
              // self.$router.push("/verify");
              this.popupMessage = 'credentials';
              this.showSuccessModal = true;
            })
            .catch((err) => {
              this.importing = false;
              console.error('Error Emiting File:', err);
              this.errorMsg = this.$t('inBulkModal.error');
              // TO DO :: remove file from list
            });
        }, 0);
      });
    },
    uploadFiles: function (f) {
      var self = this;
      this.errorMsg = null;
      function loadFiles(file) {
        self.file = file;
        setTimeout(() => {
          let wrapper = document.getElementById('scroll');
          if (wrapper)
            wrapper.scrollBy({ top: 200, left: 0, behavior: 'smooth' });
        }, 200);
      }

      for (var i = 0; i < f.length; i++) {
        console.log(f[i]);
        var ext = f[i].name.match(/\.([^.]+)$/)[1];
        switch (ext) {
          case 'csv':
            loadFiles(f[i]);
            break;
          default:
            console.error('Rejected file type ', f[i].name);
            this.errorMsg = this.$t('inBulkModal.errorFile');
        }
      }
    },
    determineDragAndDropCapable() {
      var div = document.createElement('div');

      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
      );
    },
    openFileSelection() {
      this.$refs.pdf.click();
    },
    onDragOver(event) {
      event.preventDefault();

      this.hightlight = true;
    },
    onDragLeave(event) {
      event.preventDefault();

      this.hightlight = false;
    },
    close() {
      this.$emit('close');
    },
  },
  data() {
    return {
      step: 0,
      password: '',
      file: null,
      importingCredentials: false,
      headers: [],
      templateTable: [],
      templateValues: [],
      tableValues: [],
      showConfirmData: false,
      hightlight: false,
      hasWallet: -1,
      email: null,
      sending: false,
      reload: true,
      walletAddress: null,
      buttonText: this.$t('button.next'),
      errorMsg: false,

      templateCopy: null,

      showSuccessModal: false,
      popupMessage: 'storing',
      pages: ['#page'],

      customTemplate: { 'wallid.io': true },
    };
  },
};
</script>

<style lang="scss">
.issue-bulk {
  .passepartout {
    border-color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .page {
      box-shadow: 0 2px 4px 0 rgba(209, 209, 209, 0.5);
      textarea {
        overflow: hidden;
      }
    }
  }
  div.steps {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--teal-blue);
  }
  h2.title_header,
  div.steps {
    display: inline;
  }
  .error-upload {
    &.outside-box {
      border-color: var(--coral);
    }
    &.light_text {
      margin: 0 auto;
      max-width: 60%;
      color: var(--coral) !important;
    }
  }
  .table_in_bulk {
    & > .v-data-table__wrapper > table > tbody > tr > td {
      font-size: 12px;
      padding-right: 0px;
    }
  }
  width: 100%;
  .file-listing {
    border-radius: 4px;
    border: solid 1px #b8b9bb;
    display: flex;
    align-items: center;
    padding: 12px;
    padding-left: 16px;
    p {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: var(--charcoal-grey);
      margin-bottom: 0;
    }
    .btn-close {
      height: 20px;
    }
  }
  .outside-box {
    border: dashed 1px #b8b9bb;
    border-radius: 4px;
    .hightlight {
      background-color: #eaf2f3 !important;
    }
    .drag-drop-form {
      padding: 50px 100px 30px;
      margin: 10px;
      border-radius: 4px;
      &:hover {
        background: #f7f7f7;
      }
    }
    .drag-box-text {
      max-width: 200px;
      margin-top: 22px;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: var(--teal-blue);
    }
  }
  .modal-body.scroll {
    max-height: 420px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .static_image {
    min-width: 380px;
    .id-card {
      position: absolute;
    }
  }
  .input-field {
    .v-input--radio-group {
      .v-input__slot {
        border: none !important;
      }
    }
    .v-input__control {
      min-height: unset;
      .v-input__slot {
        margin-bottom: 4px;
        height: 44px;
        border-radius: 3px;
        border: solid 1px var(--light-grey-blue);
      }
      .v-text-field__details {
        margin-bottom: 4px;
      }
    }
  }
}
</style>
